import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'app-my-app',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {

  constructor() {
  }

    ngOnInit() {
    }
}

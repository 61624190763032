import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

declare const $: any;

// Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

// Admin Menu Items
export const ADMIN_ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icontype: "dashboard",
  },
  {
    path: "/admins",
    title: "Users",
    type: "link",
    icontype: "group",
  },
  {
    path: "/meetings",
    title: "Meetings",
    type: "sub",
    icontype: "event",
    collapse: "pages",
    children: [
      { path: "camp", title: "Camp", ab: "C" },
      { path: "zoom", title: "Zoom", ab: "Z" },
      { path: "oneday", title: "One Day", ab: "D" },
    ],
  },
  {
    path: "/events/event",
    title: "Events",
    type: "link",
    icontype: "event",
  },
  {
    path: "/registration",
    title: "Registration",
    type: "link",
    icontype: "group_add",
  },
  {
    path: "/locations",
    title: "Locations",
    type: "link",
    icontype: "pin_drop",
  },
  {
    path: "/notifications",
    title: "Notifications",
    type: "link",
    icontype: "account_circle",
  },
  {
    path: "/countries",
    title: "Countries",
    type: "link",
    icontype: "public",
  },
  {
    path: "/prayer-groups",
    title: "Prayer Groups",
    type: "link",
    icontype: "church",
  },
  {
    path: "/contacts",
    title: "contacts",
    type: "link",
    icontype: "contacts",
  },
  {
    path: "/banners",
    title: "banners",
    type: "link",
    icontype: "post_add",
  },
  {
    path: "/feeds",
    title: "feeds",
    type: "link",
    icontype: "public",
  },
];
// Admin Menu Items
export const STAFF_ROUTES: RouteInfo[] = [
  {
    path: "/admins",
    title: "Users",
    type: "link",
    icontype: "group_add",
  },
  {
    path: "/registration",
    title: "Camp",
    type: "link",
    icontype: "account_circle",
  },
];
export const MAN_ROUTES: RouteInfo[] = [
  {
    path: "/meetings",
    title: "Meetings",
    type: "sub",
    icontype: "event",
    collapse: "pages",
    children: [
      { path: "camp", title: "Camp", ab: "C" },
      { path: "zoom", title: "Zoom", ab: "Z" },
      { path: "oneday", title: "One Day", ab: "D" },
    ],
  },
  {
    path: "/registration",
    title: "Camp",
    type: "link",
    icontype: "group_add",
  },
  {
    path: "/admins",
    title: "Users",
    type: "link",
    icontype: "account_circle",
  },
  {
    path: "/prayer-groups",
    title: "Prayer Groups",
    type: "link",
    icontype: "church",
  },
  {
    path: "/contacts",
    title: "contacts",
    type: "link",
    icontype: "contacts",
  },
];
export const LEADER_ROUTES: RouteInfo[] = [
  {
    path: "/meetings",
    title: "Meetings",
    type: "sub",
    icontype: "event",
    collapse: "pages",
    children: [
      { path: "camp", title: "Camp", ab: "C" },
      { path: "zoom", title: "Zoom", ab: "Z" },
      { path: "oneday", title: "One Day", ab: "D" },
    ],
  },
  {
    path: "/registration",
    title: "Camp",
    type: "link",
    icontype: "group_add",
  },
  {
    path: "/admins",
    title: "Users",
    type: "link",
    icontype: "account_circle",
  },
];
//{
//path: '/participants',
//title: 'Participants',
//type: 'link',
//icontype: 'people'
//}
// {
//   path: '/exhibitor',
//   title: 'Exhibitor',
//   type: 'link',
//   icontype: 'explicit'
// }
// {
//   path: '/delegates',
//   title: 'Delegates',
//   type: 'link',
//   icontype: 'face'
// }, {
//   path: '/speaker',
//   title: 'Speakers',
//   type: 'link',
//   icontype: 'record_voice_over'
// }, {
//   path: '/hotel',
//   title: 'Hotels',
//   type: 'link',
//   icontype: 'hotel'
// }, {
//   path: '/contacts',
//   title: 'Contacts',
//   type: 'link',
//   icontype: 'contacts'
// }, {
//   path: '/sponsors',
//   title: 'Sponsors',
//   type: 'link',
//   icontype: 'monetization_on'
// }
// {
//   path: '/attendees',
//   title: 'Attendees',
//   type: 'link',
//   icontype: 'record_voice_over'
// },
@Component({
  selector: "app-sidebar-cmp",
  templateUrl: "sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  logo: string;

  constructor(private translate: TranslateService) { }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  ngOnInit() {
    if (localStorage.getItem("lang") !== "ar") {
      this.translate.setDefaultLang("en");
      this.translate.use("en");
    } else {
      this.translate.setDefaultLang("ar");
      this.translate.use("ar");
    }

    if (localStorage.getItem("lang") !== "en") {
      this.logo = "assets/img/logo3.png";
    } else {
      this.logo = "assets/img/logo3.png";
    }

    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user.roles.some((role) => role.name === "Admin")) {
        this.menuItems = ADMIN_ROUTES.filter((menuItem) => menuItem);
      } else if (user.roles.some((role) => role.name === "Leader")) {
        this.menuItems = LEADER_ROUTES.filter((menuItem) => menuItem);
      } else if (user.roles.some((role) => role.name === "Manager")) {
        this.menuItems = MAN_ROUTES.filter((menuItem) => menuItem);
      } else {
        this.menuItems = STAFF_ROUTES.filter((menuItem) => menuItem);
      }
    } else {
      this.menuItems = STAFF_ROUTES.filter((menuItem) => menuItem);
    }
  }
  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>(
        document.querySelector(".sidebar .sidebar-wrapper")
      );
    }
  }
  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }

  changeLang(language: string) {
    this.translate.use(language);
    localStorage.setItem("lang", language);
    if (language !== "en") {
      this.logo = "assets/img/moi_logo_ar.png";
      const body = document.getElementsByTagName("body")[0];
      body.classList.add("rtl");
      location.reload();
    } else {
      this.logo = "assets/img/moi_logo.png";
      const body = document.getElementsByTagName("body")[0];
      body.classList.remove("rtl");
      location.reload();
    }
  }
}

<div class="logo">
  <a class="simple-text logo-mini">
    <div class="logo-img">
      <img [src]="logo" />
    </div>
  </a>
</div>


<div class="sidebar-wrapper">
  <div *ngIf="isMobileMenu()">
    <ul class="nav navbar-nav nav-mobile-menu">
      <li class="nav-item dropdown">
        <a class="nav-link" href="#pablo" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="material-icons">language</i>
          <p>
            <span class="d-lg-none d-md-block">{{'Language' | translate}}</span>
          </p>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" href="javascript:;" (click)="changeLang('en')">
            English
          </a>
          <a class="dropdown-item" href="javascript:;" (click)="changeLang('ar')">
            عربى
          </a>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0);" (click)="logout()">
          <i class="material-icons">power_settings_new</i>
          <p>
            <span class="d-lg-none d-md-block">{{'Logout' | translate}}</span>
          </p>
        </a>
      </li>
    </ul>
  </div>
  <ul class="nav">
    <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item">
      <!--If is a single link-->
      <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'" class="nav-link">
        <i class="material-icons">{{menuitem.icontype}}</i>
        <p>{{menuitem.title | translate}}</p>
      </a>
      <!--If it have a submenu-->
      <a data-toggle="collapse" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub'" (click)="updatePS()" class="nav-link">
        <i class="material-icons">{{menuitem.icontype}}</i>
        <p>{{menuitem.title | translate}}<b class="caret"></b></p>
      </a>

      <!--Display the submenu items-->
      <div id="{{menuitem.collapse}}" class="collapse" *ngIf="menuitem.type === 'sub'">
        <ul class="nav">
          <li routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item">
            <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link">
              <span class="sidebar-mini">{{childitem.ab}}</span>
              <span class="sidebar-normal">{{childitem.title | translate}}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>

</div>
